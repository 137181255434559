import { ResizeObserverManager } from "./Observers/Resize/ResizeObserverManager";
import { IntersectionObserverManager } from "./Observers/Intersection/IntersectionObserverManager";
import { LocalStorageProxy } from "./WebStorage/LocalStorageProxy";
import { SessionStorageProxy } from "./WebStorage/SessionStorageProxy";

const BrowserApisInit = {
  BrowserApis: {
    ResizeObserverManager: new ResizeObserverManager(),
    IntersectionObserverManager: new IntersectionObserverManager(),
    LocalStorageProxy: new LocalStorageProxy(),
    SessionStorageProxy: new SessionStorageProxy()
  },
  initialize: () => {
    const StaticName = "BrowserApis";
    if (typeof window != "undefined" && !window[StaticName]) {
      window[StaticName] = {
        ...BrowserApisInit.BrowserApis,
      };
    } else {
      window[StaticName] = {
        ...window[StaticName],
        ...BrowserApisInit.BrowserApis,
      };
    }
  },
};

BrowserApisInit.initialize();
